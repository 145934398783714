import { useContext, useState } from 'react';
import {
  ListSubheader,
  alpha,
  Box,
  List, Tooltip, IconButton,
  styled, Grid,
  Button, DialogContent,
  ListItem, Typography, ListItemButton, Paper
} from '@mui/material';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { ResponseLogin } from 'src/models/login';
import { TokenContext } from 'src/contexts/UserContext';
import { createStyles, makeStyles } from '@mui/styles';
import { ListItemText, Theme } from '@material-ui/core';
import icono from '../../../../assets/iconspublic/DESCARGAR-CDR-PASIVO_32X32.svg';
import inicioPasivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-INICIO-PASIVO_68X56.svg';
import inicioActivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-INICIO-ACTIVO_68X56.svg';
import inicioHover from '../../../../assets/icons/SIDEBAR/SIDEBAR-INICIO-HOVER_68X56.svg';
import docPasivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-COMPROBANTE-PASIVO_68X56.svg';
import docActivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-COMPROBANTE-ACTIVO_68X56.svg';
import docHover from '../../../../assets/icons/SIDEBAR/SIDEBAR-COMPROBANTE-HOVER_68X56.svg';
import bajaActivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-BAJAS-ACTIVO_68X56.svg';
import bajaPasivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-BAJAS-PASIVO_68X56.svg';
import bajaHover from '../../../../assets/icons/SIDEBAR/SIDEBAR-BAJAS-HOVER_68X56.svg';
import guiaPasivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-GUIAS-PASIVO_68X56.svg';
import guiaActivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-GUIAS-ACTIVO_68X56.svg';
import guiaHover from '../../../../assets/icons/SIDEBAR/SIDEBAR-GUIAS-HOVER_68X56.svg';
import cobranzaPasivo from '../../../../assets/icons/SIDEBAR/LOCALES-PASIVO_68X56.svg';
import cobranzaActivo from '../../../../assets/icons/SIDEBAR/LOCALES-ACTIVO_68X56.svg';
import cobranzaHover from '../../../../assets/icons/SIDEBAR/LOCALES-HOVER_68X56.svg';
import repositorioPasivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-REPOSITORIO-PASIVO_68X56.svg';
import repositorioActivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-REPOSITORIO-ACTIVO_68X56.svg';
import repositorioHover from '../../../../assets/icons/SIDEBAR/SIDEBAR-REPOSITORIO-HOVER_68X56.svg';
import sbOpen from '../../../../assets/icons/SIDEBAR/SIDEBAR ABRIR_52X48.svg';
import sbClose from '../../../../assets/icons/SIDEBAR/SIDEBAR CERRAR_52X48.svg';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      zIndex: 1,
      // width: '68px !important',
      // paddingTop:40,
      backgroundColor: 'white',
      position: 'fixed',
      boxShadow: '4px 0px 10px 0px rgba(25, 67, 127, 0.12)',
      '& .MuiButtonBase-root': {
        padding: 0,
      },
      '& .MuiList-root': {
        padding: '0px'
      },
    },
    paper: {
      position: 'absolute',
      top: 0,
      zIndex: 1,
      padding: 0,
      '&.MuiPaper-root': {
        boxShadow: '8px 8px 10px 0px rgba(25, 67, 127, 0.10)',
        position: 'absolute',
        top: 0,
        zIndex: 1,
        padding: 0,
      }
    },
    listItem: {
      // '&:hover': {
      //   backgroundColor: 'black',
      // },
      display: 'flex',
      alignItems: 'center',
      position: 'fixed',
      '&.MuiListItem-root': {
        justifyContent: 'center',
        padding: '0px',
        "&:hover": {
          backgroundColor: 'var(--colorQuaternary-Green)',
        }
      }
    }
  }),
);

interface Props {
  response: ResponseLogin
}

const SidebarMenu = ({ response }: Props) => {
  const classes = useStyles();
  const { idSelectedIndexMenu, selectedIndexMenu, } = useContext(TokenContext);
  const [hoveredItem, setHoveredItem] = useState<any | null>(null);
  const { sidebarToggle, toggleSidebar, closeSidebar } = useContext(SidebarContext);
  const [tesoreria, setTesoreria] = useState('ADMIN');
  const [acceso, setAcceso] = useState(response.idrol);

  // const usuario = response.name.toLowerCase().trim().split(' ').map(v => v[0].toUpperCase() + v.substr(1)).join(' ');
  // const accesos_nav = response.acceso;

  const handleListItemClick = (index: any) => {
    idSelectedIndexMenu(index)
    navigate(menuItems[index].rutaPrincipal)
    // closeSidebar
  };

  const menuItems = [
    {
      imgPasivo: inicioPasivo,
      imgHover: inicioHover,
      imgActivo: inicioActivo,
      descripcion: "Inicio",
      // rutaPrincipal: "/menu",
      rutaPrincipal: "/reporte",
      opciones: [
      ],
      // activo: accesos_nav.comprobantes,
      activo: true,
    }
    ,
    {
      imgPasivo: docPasivo,
      imgHover: docHover,
      imgActivo: docActivo,
      descripcion: "Reporte Gerencia",
      // rutaPrincipal: "/reporte/repSectorista",
      opciones: [
        {
          link: '/reporte/repSectorista',
          label: 'Por Sectorista'
        }, {
          link: '/reporte/repCliente',
          label: 'Por Cliente'
        },
        {
          link: '/reporte/UploadExcel',
          label: 'Cargar Data'
        }

      ],
      activo: acceso == 3 || acceso == 1 ? true : false,
    }
    ,
    {
      imgPasivo: repositorioPasivo,
      imgHover: repositorioHover,
      imgActivo: repositorioActivo,
      // imgPasivo: docPasivo,
      // imgHover: docHover,
      // imgActivo: docActivo,
      // imgPasivo: 'https://cdn-icons-png.flaticon.com/512/6330/6330675.png',
      // imgHover: 'https://cdn-icons-png.flaticon.com/512/6330/6330675.png',
      // imgActivo: 'https://cdn-icons-png.flaticon.com/512/6330/6330675.png',

      descripcion: "Factura vs Certificado Origen",
      rutaPrincipal: "/reporte/oc_factura",
      opciones: [
      ],
      activo: acceso == 2 || acceso == 1 ? true : false,
    },
    // {
    //   imgPasivo: bajaPasivo,
    //   imgHover: bajaHover,
    //   imgActivo: bajaActivo,
    //   descripcion: "Facturas y Registros Sanitarios",
    //   rutaPrincipal: "/reporte/liquidacion",
    //   opciones: [
    //     {
    //       link: '/reporte/rrss',
    //       label: 'Registros Sanitarios'
    //     },
    //     {
    //       link: '/reporte/cargaCatalogo',
    //       label: 'Cargar Catalogo'
    //     }

    //   ],
    //   activo: true
    // },
    // {
    //   imgPasivo: bajaPasivo,
    //   imgHover: bajaHover,
    //   imgActivo: bajaActivo,
    //   descripcion: "Reporte de Bajas",
    //   rutaPrincipal: "/menu/resumenbajas",
    //   opciones: [
    //   ],
    //   activo: true
    // },
    // {
    //   imgPasivo: guiaPasivo,
    //   imgHover: guiaHover,
    //   imgActivo: guiaActivo,
    //   descripcion: "Guías",
    //   rutaPrincipal: "/menu/guiasremision",
    //   // rutaPrincipal: "/status/404",
    //   opciones: [
    //   ],
    //   activo: true
    // },
    // {
    //   imgPasivo: cobranzaPasivo,
    //   imgHover: cobranzaHover,
    //   imgActivo: cobranzaActivo,
    //   descripcion: "Administración de Locales",
    //   rutaPrincipal: "/menu/admCobranza",
    //   // rutaPrincipal: "/status/404",
    //   opciones: [
    //   ],
    //   activo: tesoreria === 'COBRANZA' || tesoreria === 'ADMIN' ? true : false,
    // },
    // {
    //   imgPasivo: repositorioPasivo,
    //   imgHover: repositorioHover,
    //   imgActivo: repositorioActivo,
    //   descripcion: "Repositorio de Comprobantes",
    //   rutaPrincipal: "/menu/repositorio",
    //   // rutaPrincipal: "/status/404",
    //   opciones: [
    //   ],
    //   activo: tesoreria === 'COBRANZA' ? false : true,
    // },

  ];



  const navigate = useNavigate();

  const handleMouseEnter = (item: any) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <>
      <Box className={classes.root} sx={{
        width: '68px !important',
        paddingTop: {
          xl: '40px', lg: '40px', md: '40px',
          // sm: openSideBar ? '6px' : '0px', xs: openSideBar ? '6px' : '0px'
          sm: '6px', xs: '6px'
        }
      }} >
        <Box sx={{
          display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' }
        }}>
          <IconButton color="primary" onClick={toggleSidebar}>
            {!sidebarToggle ? (
              <img src={sbOpen} />
            ) : (
              <img src={sbClose} />
            )}
          </IconButton>
        </Box>
        <List component="nav" >
          {menuItems.map((o, index) => (
            o.activo === true ?
              <ListItem
                // selected={selectedIndexMenu === index}
                key={index}
                className={classes.listItem}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleListItemClick(index)}
                sx={{
                  marginBottom: '20px',
                  cursor: o.opciones.length === 0 ? 'pointer' : ''
                }} >
                <ListItemText
                // primary={index} 
                />
                <img style={{
                  boxShadow: hoveredItem === index ? '0px 4px 14px 0px rgba(25, 67, 127, 0.16)' : '', width: '68px', height: '56px'
                }}
                  src={hoveredItem === index ? o.imgHover :
                    selectedIndexMenu === index ? o.imgActivo : o.imgPasivo} />
                {hoveredItem === index && (
                  <Paper elevation={3} className={classes.paper} sx={{
                    '&.MuiPaper-root': {
                      // right: index === 1 ? '-234px' : index === 2 ? '-178px' : index === 3 ? '-86px' : '-83px', // Adjust this value to control how far the list protrudes
                      right: index === 1 ? '-181px' : index === 2 ? '-278px' : index === 3 ? '-290px' : index === 4 ? '-254px' : index === 5 ? '-284px' : '-82px', // Adjust this value to control how far the list protrudes
                      width: 'auto',
                    }
                  }}>
                    <List sx={{
                      backgroundColor: 'var(--colorQuaternary-Green)', // bg submenu
                      borderRadius: '0px 8px 8px 0px',
                    }}>
                      <ListItem className={classes.listItem} onClick={o.opciones.length === 0 ?
                        () => { navigate(o.rutaPrincipal); } :
                        () => { console.log('0000000000000000'); }}
                        sx={{
                          height: '56px', cursor: o.opciones.length === 0 ? 'pointer' : '',
                          borderRadius: '0px 8px 0px 0px',
                        }}>
                        <Box sx={{
                          padding: '0px 20px 0px 20px',
                          height: '100%',
                          display: 'flex', alignItems: 'center',
                          borderRadius: '0px 8px 0px 0px',
                        }}>
                          <Typography sx={{
                            color: 'var(--contrastementa)',
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '24px',
                          }}>
                            {o.descripcion}
                          </Typography>
                        </Box>
                      </ListItem>
                      {o.opciones.length > 0 ?
                        o.opciones.map((opciones, index) => (
                          <ListItemButton className={classes.listItem}
                            key={index}
                            href={opciones.link}
                            sx={{
                              height: '48px',
                              width: 'auto',
                              display: 'flex', alignItems: 'center',
                            }}
                          >
                            <Box sx={{
                              borderRadius: index === o.opciones.length - 1 ? '0px 0px 8px 0px' : '0px',
                              padding: '0px 20px 0px 20px',
                              color: 'var(--colorPrimary-Gray)',
                              fontFamily: 'Poppins',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              lineHeight: '20px',
                              height: '100%', width: '100%',
                              display: 'flex', alignItems: 'center',
                              cursor: 'pointer',
                              ":hover": {
                                color: '#fff',
                                backgroundColor: 'var(--secundarioceleste)',
                              },
                            }}>
                              {opciones.label}
                            </Box>
                          </ListItemButton>
                        )) : ''}
                    </List>
                  </Paper>
                )}

              </ListItem>
              : ''
          ))}
        </List>
      </Box>

    </>
  );
}

export default SidebarMenu;
