
import * as React from 'react';
import CSS from 'csstype';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { ConsultaDatosRecep, ConsultaxCodigo_Recep, ConsultaxCodigoCliente_Recep, ConsultDocumentRecep } from 'src/models/consultaDocumento';
import { BajasBoleta, BajasFactura } from 'src/models/bajaDocumento';
import Checkbox from '@mui/material/Checkbox';
import ArrowDown from '../../../../assets/icons/TABLAS/ORDENAR-ACTIVO-ABAJO_16X16.svg';
import ArrowUp from '../../../../assets/icons/TABLAS/ORDENAR-ACTIVO-ARRIBA_16X16.svg';
import ArrowPasive from '../../../../assets/icons/TABLAS/ORDENAR-PASIVO-16X16.svg';
import { Locales } from 'src/models/localesCobranza';

const TableRowColumn: CSS.Properties = {
    padding: '0px',
    height: '44px',
    // textAlign: 'center'
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(1),
        },
        table: {
            minWidth: 1200,
            width: '100%',
            marginBottom: theme.spacing(1),
        },

        tableCell: {
            padding: '0px',
            // height: '10px',
            display: 'flex',
            justifyItems: 'center',
            alignItems: 'center',
        },
        tablerow: {

        },

        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 10,
            width: 1,
        },
    }),
);

interface HeadCell {
    disablePadding: boolean;
    id: keyof ConsultDocumentRecep;
    label: string;
    numeric: boolean;
    width: string;
    left: string;
    visible: boolean
}

type Order = 'asc' | 'desc';


interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ConsultaDatosRecep | keyof ConsultDocumentRecep | keyof BajasFactura | keyof BajasBoleta | keyof Locales | keyof ConsultaxCodigo_Recep | keyof ConsultaxCodigoCliente_Recep) => void;
    order: Order;
    orderBy: string;
    headCellsp: any;
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount?: number;
    numSelected?: any;
}
const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { classes, order, orderBy, onRequestSort, headCellsp,
        rowCount,
        numSelected,
        onSelectAllClick
    } = props;
    const createSortHandler = (property: keyof ConsultDocumentRecep | keyof BajasFactura | keyof BajasBoleta | keyof Locales | keyof ConsultaxCodigo_Recep | keyof ConsultaxCodigoCliente_Recep) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    const headCells: readonly HeadCell[] = headCellsp;


    return (

        <TableHead sx={{ backgroundColor: '#E8F6F3', color: '#232323' }}>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell> */}
                {headCells.map((headCell) => (

                    <TableCell
                        width={headCell.width}
                        sx={{
                            backgroundColor: '#E8F6F3', color: '#232323', display: headCell.visible === true ? '' : 'none',
                            width: headCell.width,
                            // padding: '0px' 
                        }}
                        key={headCell.id}
                        style={TableRowColumn}
                        sortDirection={orderBy === headCell.id ? order : false}                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            sx={{
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                width: headCell.width, color: '#232323',
                                // paddingLeft: 10,
                                padding: headCell.left,
                                textAlign: headCell.numeric == true ? 'center' : 'left',
                                backgroundColor: '#E8F6F3',
                                '&.MuiButtonBase-root': {
                                    '&.MuiTableSortLabel-root': {
                                        '.MuiTableSortLabel-icon': {
                                            display: 'none'
                                        }
                                    }
                                },
                            }} >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                // <span className={classes.visuallyHidden}>
                                //     {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                // </span>
                                <img src={order === 'desc' ? ArrowUp : ArrowDown} style={{ marginLeft: '4px' }} />
                            ) : <img src={ArrowPasive} style={{ marginLeft: '4px' }} />}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
export default EnhancedTableHead;